.customer-details {
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    gap: 5px;
    font-size: 20px;
  }

  > span {
    text-transform: uppercase;
    color: gray;
    font-size: 10px;
    font-weight: bold;
  }
}
