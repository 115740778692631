@use "styles/variables.scss" as *;

.view-tab-list {
  width: 100%;
  height: 100%;

  > header {
    height: $header-filter-height;

    > .header-filter {
      border-top: none;
    }
  }

  > .table {
    height: calc(100% - $header-filter-height);
  }
}
