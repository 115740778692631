@use "../../../../styles/variables.scss" as *;

#tenant-settings-view {
  main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 16px;
    row-gap: 16px;
    column-gap: 16px;
    height: unset;

    > .tenant-settings-panel {
      background-color: white;
      border-radius: 5px;
      border: 1px solid var(--rs-list-border);
      width: 250px;
      height: 300px;

      > .tenant-settings-header {
        padding: 15px;
        font-size: 20px;
        font-weight: bold;
      }

      > .tenant-settings-body {
        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 15px;
          margin: 0;
          font-size: 16px;

          &:hover {
            font-weight: bold;
            cursor: pointer;
          }
        }
      }
    }
  }
}
