#tenant-settings-tax-groups-view {
  .role-name {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .table-column-name {
    flex: 1;
    min-width: 100px;
    align-items: flex-start;
  }

  .table-column-taxes {
    flex: 2;
    min-width: 100px;
    align-items: flex-start;
  }

  .table-column-rate {
    width: 75px;
    justify-content: flex-end;
  }
}
