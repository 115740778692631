@use "styles/variables.scss" as *;

.sidebar {
  display: none;
  flex-direction: column;
  height: 100%;
  width: $sidebar-width;
  border-right: 2px solid $primary-color;
  color: white;

  > .sidebar-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $sidebar-header-height;
    background-color: #1c3b55;
    border-bottom: 2px solid $primary-color;
    cursor: pointer;
    width: 100%;

    &.sidebar-header-disabled {
      cursor: not-allowed;
    }

    > img {
      height: $navbar-height;
    }
  }

  > .sidebar-body {
    height: calc(100% - $sidebar-header-height);
    background-color: #385d79;
    width: 100%;

    > .nav {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 7.5px;
      gap: 7.5px;

      > .nav-title {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 15px 5px 0 5px;
        font-weight: bold;
        font-size: 10px;
        text-transform: uppercase;
        color: white;
      }

      .nav-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        gap: 10px;
        width: 100%;
        padding: 10px 15px;
        background-color: #1c3b55;
        border: 1px solid $primary-color;
        user-select: none;
        text-transform: uppercase;
        font-weight: bold;
        color: #e7e7ef;

        &:hover {
          background-color: $primary-color;
          cursor: pointer;
        }

        &.nav-item-active {
          background-color: $primary-color;
          cursor: auto;
        }

        &.nav-item-disabled {
          background-color: #485d6e;
          cursor: not-allowed;
        }

        > .nav-content {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          width: 100%;
          align-items: center;
          font-size: 12px;
          gap: 10px;
        }
      }

      > .nav-menu {
        > .nav-item {
          background-color: #385d79;
          margin-bottom: 5px;

          &:last-child {
            margin-bottom: 0;
          }

          &:hover {
            background-color: $primary-color;
          }
        }
      }
    }
  }
  > .sidebar-footer {
  }
}
