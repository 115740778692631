.panel {
  margin-bottom: 16px;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  padding: 25px 33px;
  background-color: white;
  border: solid var(--rs-list-border) 1px;
  border-radius: 10px;
  gap: 15px;
  max-width: 1200px;

  &:first-child {
    margin-top: 16px;
  }

  > .panel-header {
    > .panel-title {
      font-size: 20px;
    }
  }

  > .panel-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
