.view-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  > header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    padding: 0 16px;
    border-bottom: solid var(--rs-list-border) 1px;

    h1 {
      font-size: 25px;
    }
  }

  > main {
    display: flex;
    flex-direction: column;
    height: calc(100% - 70px - 82px);
    overflow-y: auto;

    &.loading {
      pointer-events: none;

      &:before {
        content: "";
        position: absolute;
        top: 70px + 56px;
        left: 200px;
        bottom: 82px;
        width: calc(100% - 200px);
        height: calc(100% - 70px - 56px - 82px);
        background-color: transparent;
        z-index: 1000000;
      }
    }

    .rs-form {
      height: 100%;
    }
  }

  > footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 82px;
    padding: 0 16px;
    border-top: solid var(--rs-list-border) 1px;
    background-color: #f7f7fa;
    gap: 10px;

    > .rs-btn {
      width: 250px;
    }
  }
}
