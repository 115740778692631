@use "styles/variables.scss" as *;

#report-billing {
  display: inline-block;

  .document {
    .table {
      margin-bottom: 35px;

      > .table-header {
        color: $primary-color;
        border-top: solid 1px var(--rs-list-border);
        background-color: #f7f7fa;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-weight: normal;

        > div {
          &:first-child {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 7.5px;

            > span {
              &:first-child {
                font-size: 25px;
                font-weight: bold;
              }
            }

            > .text-subtile {
              font-size: 18px;
            }
          }

          &:last-child {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;

            > span {
              &:first-child {
                font-size: 10px;
                text-transform: uppercase;
              }

              &:last-child {
                font-size: 20px;
                font-weight: bold;
              }
            }
          }
        }
      }

      > .table-content {
        > .table-body {
          background-color: #f7f7fa;

          .table-row:last-child {
            color: $primary-color;
            border-bottom: solid 1px var(--rs-list-border);
            background-color: #f7f7fa;
            font-weight: bold;
          }
        }
      }
    }

    .table-column-element {
      flex: 1;
      min-width: 300px;
    }

    .table-column-provider {
      width: 200px;
    }

    .table-column-type {
      width: 150px;
    }

    .table-column-subcontractor {
      width: 100px;
      justify-content: flex-end;
    }

    .table-column-contractor {
      width: 100px;
      justify-content: flex-end;
    }

    .table-column-promoter {
      width: 100px;
      justify-content: flex-end;
    }

    .table-column-customer {
      width: 100px;
      justify-content: flex-end;
    }

    .table-column-contengency {
      width: 100px;
      justify-content: flex-end;
    }
  }
}
