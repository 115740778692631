#report-form {
  .table-column-index {
    width: 60px;
  }

  .table-column-name {
    min-width: 125px;
    flex: 1;
  }

  .table-column-display {
    width: 200px;
  }
}
