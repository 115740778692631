.table-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.table-title-border {
    border-bottom: 1px solid #152e41;
  }

  h2 {
    width: 355px;
    margin: 0;
  }
}
