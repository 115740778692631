@use "styles/variables.scss" as *;

#report-summary {
  max-height: 100%;

  .document {
    display: block;
    flex-direction: unset;
    margin: unset;
    overflow: auto;
    width: 100%;
    height: 100%;
    z-index: 100;
  }

  .report-summary-table {
    font-size: 65%;
    z-index: 1000;
    border-collapse: collapse;
    border: solid #d9d9d9 1px;
    border-radius: 10px;
    border-spacing: 0;
    overflow: hidden;

    .table-cell-disabled {
      background-color: #868686 !important;
    }

    .table-head-row-1 {
      .table-head-vibe {
        color: white;
        background-color: black;
      }

      .table-head-base {
        color: white;
        background-color: #ac9660;
      }

      .table-head-extra {
        color: white;
        background-color: $primary-color;
      }
    }

    .table-head-row-2 {
      .table-head-base {
        color: white;
        background-color: #d5b567;
      }

      .table-head-extra {
        color: white;
        background-color: #1f4e78;
      }
    }

    .table-head-row-3 {
      .table-head-vibe {
        color: $primary-color;
        background-color: #a6a6a6;
      }

      .table-head-base {
        color: $primary-color;
        background-color: #d3cab3;
      }

      .table-head-extra {
        color: $primary-color;
        background-color: #adb4bd;
      }
    }

    td,
    th {
      padding: 5px;
      min-width: 100px;
      text-align: center;
      vertical-align: middle;
    }

    tbody {
      th,
      td {
        height: 50px;
      }
    }
  }
}
