#projects-view {
  .table-column-date {
    width: 100px;
  }

  .table-column-label {
    flex: 1;
    min-width: 100px;
  }

  .table-column-description {
    flex: 1;
    min-width: 100px;
  }
}
