@use "styles/variables.scss" as *;

.form-container {
  position: relative;
  padding: 20px 15px 20px 15px;
  color: $primary-color;

  .form-container-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f7f7fa;
    min-height: 150px;
    text-align: center;
    padding: 25px;
    width: 100%;
    border: 1px solid var(--rs-list-border);

    > .form-container-empty-label {
      font-size: 20px;
      font-weight: bold;
    }

    > .form-container-empty-content {
      font-size: 16px;
    }
  }

  .rs-form-group {
    flex: 1;
    width: 100%;

    > .rs-input-group,
    > .rs-input,
    > .rs-input-number,
    > .rs-picker {
      width: 100%;

      > .rs-input,
      > .rs-input-number,
      > .rs-picker {
        width: 100%;
      }
    }
  }

  &.form-container-highlight {
    background-color: #f7f7fa;
    border-bottom: 1px solid var(--rs-list-border);

    .rs-uploader-trigger-btn,
    .rs-uploader-file-item {
      background-color: white !important;
    }
  }

  &.form-container-without-border {
    border: none;
  }

  > .form-container-body,
  > .form-container-header {
    max-width: 550px !important;
  }

  > .form-container-body {
    > .form-container {
      padding: 0;
    }
  }

  > .form-container-header {
    display: flex;
    padding: 0 0 10px 0;
    flex: 1;

    > .form-container-title {
      display: flex;
      flex: 1;
      font-size: 23px;
      font-weight: bold;
    }

    > .form-container-actions {
      > button {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }

  &.form-container-with-padding {
    > .form-container-header {
      padding: 15px 15px 10px 15px;
    }
  }

  &.form-container-fluid {
    > .form-container-body,
    > .form-container-header {
      max-width: 1200px !important;
    }
  }

  > .form-container {
    border-top: 1px solid var(--rs-list-border);
    padding: 0;
    padding-top: 15px;

    > .form-container-header {
      display: flex;
      flex: 1;
      margin-bottom: 7.5px;

      > .form-container-title {
        display: flex;
        flex: 1;
        font-size: 17px;
        font-weight: bold;
      }
    }
  }
}
