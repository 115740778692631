@use "styles/variables.scss" as *;

$selection-group-title-height: 60px;
$selection-group-vibe-height: 60px;
$selection-group-mix-and-match-height: 40px;

$selection-group-total-height: 85px;
$selection-group-buttons-height: 70px;
$form-sidebar-footer-height: $selection-group-total-height + $selection-group-buttons-height;

$form-sidebar-border-color: #c0c0d7;

#contract-selection-group-form {
  display: flex;
  height: 100%;

  &.contract-selection-group-form-without-unity-model {
    > aside.form-sidebar {
      width: 100% !important;
      border-left: none !important;
    }
  }

  > .unity-model {
    display: flex;
    width: 60%;
    height: 100%;
  }

  > aside.form-sidebar {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
    background-color: #e7e7ef;
    border-left: 1px solid $primary-color;

    > .form-sidebar-header {
      display: flex;
      flex-direction: column;
      height: $selection-group-title-height;

      > .selection-group-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: var(--rs-sidenav-default-bg);
        background-color: $primary-color;
        padding: 0 15px;
        height: $selection-group-title-height;

        > h2 {
          font-size: 28px;
          line-height: 28px;
        }

        .selection-group-redirection-buttons {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
        }
      }

      > .selection-group-vibe {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: #e7e7ef;
        padding: 0 15px;
        border-bottom: 1px solid $form-sidebar-border-color;
        height: $selection-group-vibe-height;
        gap: 10px;

        > .selection-group-vibe-label {
          font-size: 20px;
          font-weight: bold;
        }

        > .selection-group-vibe-input {
          width: 250px;
        }
      }

      > .selection-group-vibe-mode {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #e7e7ef;
        border-bottom: 1px solid $form-sidebar-border-color;
        height: $selection-group-mix-and-match-height;
        padding: 0 15px;

        > .selection-group-vibe-mode-label {
          text-transform: uppercase;
          font-weight: bold;
          font-size: 14px;
        }

        > .selection-group-vibe-mode-amount {
          font-size: 14px;
        }
      }
    }

    > .form-sidebar-body {
      display: flex;
      flex-direction: column;
      height: calc(100% - $selection-group-title-height - $form-sidebar-footer-height);
      overflow-y: auto;
      background-color: white;
      padding: 10px 15px;
      padding-bottom: 25px;
    }

    > .form-sidebar-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: $form-sidebar-footer-height;

      > .selection-summary {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #e7e7ef;
        padding: 0 15px;
        border-top: 1px solid $form-sidebar-border-color;
        height: $selection-group-total-height;
        width: 100%;
        gap: 5px;

        > .selection-summary-total-section,
        > .selection-summary-total {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
        }

        > .selection-summary-total-section {
          font-size: 16px;
        }

        > .selection-summary-total {
          font-size: 22px;
          font-weight: bold;
        }
      }

      > .selection-group-buttons {
        border-top: 1px solid $form-sidebar-border-color;
        display: flex;
        flex-direction: row;
        background-color: #e7e7ef;
        padding: 15px;
        height: $selection-group-buttons-height;
        gap: 15px;
        width: 100%;

        > button {
          width: 100%;
        }
      }
    }

    &.form-sidebar-has-vibe {
      > .form-sidebar-header {
        height: $selection-group-title-height + $selection-group-vibe-height;
      }

      > .form-sidebar-body {
        height: calc(100% - ($selection-group-title-height + $selection-group-vibe-height) - $form-sidebar-footer-height);
      }
    }

    &.form-sidebar-has-mix-and-match {
      > .form-sidebar-header {
        height: $selection-group-title-height + $selection-group-mix-and-match-height;
      }

      > .form-sidebar-body {
        height: calc(100% - ($selection-group-title-height + $selection-group-mix-and-match-height) - $form-sidebar-footer-height);
      }
    }

    &.form-sidebar-has-vibe.form-sidebar-has-mix-and-match {
      > .form-sidebar-header {
        height: $selection-group-title-height + $selection-group-vibe-height + $selection-group-mix-and-match-height;
      }

      > .form-sidebar-body {
        height: calc(100% - ($selection-group-title-height + $selection-group-vibe-height + $selection-group-mix-and-match-height) - $form-sidebar-footer-height);
      }
    }
  }
}
