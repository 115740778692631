#tenant-settings-roles-view {
  .role-name {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .table-column-name {
    flex: 1;
    min-width: 100px;
    align-items: flex-start;
  }

  .table-column-description {
    flex: 2;
    min-width: 100px;
    align-items: flex-start;
  }
}
