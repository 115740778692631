#project-products-view {
  .table-column {
    align-items: flex-start;

    &.table-column-label {
      flex: 1;
      min-width: 400px;
    }

    &.table-column-is-valid {
      width: 50px;
    }

    &.table-column-activities {
      flex: 1;
      min-width: 125px;

      .product-activity-list {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
    }

    &.table-column-provider {
      flex: 1;
      min-width: 125px;
    }

    &.table-column-provider-product-model {
      flex: 1;
      min-width: 125px;
    }

    &.table-column-provider-product-code {
      flex: 1;
      min-width: 125px;
    }
  }

  .table-row-highlight > .table-column {
    align-items: center;
  }
}
