@use "styles/variables.scss" as *;

.dialog-body-delete {
  > .dialog-danger-zone {
    text-transform: uppercase;
    color: #ff0000;
    margin-bottom: 20px;
  }

  .dialog-definition-list {
    background-color: #f7f7fa;
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    border-radius: 7.5px;

    .dialog-definition-item {
      display: flex;
      align-items: baseline;
      gap: 10px;
      margin: 0;
      align-items: center;

      dt {
        text-transform: uppercase;
        width: 150px;
        font-weight: 100;
        margin: 0;
        font-size: 10px;
      }

      dd {
        font-size: 12px;
        flex: 1;
        font-weight: bold;
        margin: 0;
        color: $primary-color;
      }
    }
  }

  .dialog-object-name {
    background-color: #f7f7fa;
    color: $primary-color;
    font-weight: bold;
  }
}
