@use "styles/variables.scss" as *;

#user-form {
  .user-tenant-panel {
    margin-bottom: 25px;
    border: 2px solid #e5e5ea;

    > .user-tenant-panel-header {
      background-color: #f7f7fa;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      border-bottom: 2px solid #e5e5ea;
      font-weight: bold;
      font-size: 18px;

      > .user-tenant-input {
        display: flex;
        align-items: center;
        gap: 30px;

        > .has-access-to-all-projects {
          display: flex;
          align-items: center;
          gap: 10px;
          font-weight: normal;
          font-size: 14px;
        }
      }
    }

    > .user-tenant-panel-body {
      font-size: 18px;

      > .user-tenant-project {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        border-bottom: 1px solid #e5e5ea;

        &:last-child {
          border-bottom: none;
        }
      }

      > .user-tenant-project-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
      }
    }
  }
}
