.header-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  flex: 1;

  > .header-toolbar-buttons {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
  }
}
