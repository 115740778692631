@use "styles/variables.scss" as *;

.form-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7fa;
  border: 3px solid var(--rs-list-border);
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  > .form-panel-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 70px;
    width: 100%;

    > .form-panel-start {
      width: 30px;
      display: flex;
      justify-content: center;
      padding: 10px 0 10px 10px;
      align-items: center;
      flex-direction: column;

      > .form-panel-index {
        font-size: 10px;
      }

      > button {
        padding: 0;
        margin: 0;
      }
    }

    > .form-panel-center {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: calc(100% - 30px - 40px);
      flex: 1;
      padding: 10px;
      gap: 10px;

      > .rs-form-group {
        display: flex;
        flex-direction: column;
        margin: 0;
        flex: unset;
        min-width: unset;

        > .rs-form-control-label {
          font-size: 8px;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }

    > .form-panel-end {
      background-color: var(--rs-menuitem-active-bg);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 40px;
      border-left: 1px solid var(--rs-list-border);
      padding: 0;
    }
  }

  > .form-panel-body {
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: white;
    border-top: 1px solid var(--rs-list-border);

    .form-panel-body-header {
      border-bottom: 1px solid var(--rs-list-border);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px 0 10px;

      .form-panel-body-header-left-nodes {
        display: flex;
        gap: 10px;
      }

      .form-panel-body-header-right-nodes {
        display: flex;
        gap: 10px;
      }
    }

    .table {
      .table-row {
        .table-column {
          padding: 4px 5px;

          &:last-child {
            margin-left: 5px;
          }
        }
      }

      .table-head {
        .table-row {
          background-color: #f7f7fa;
          color: $primary-color !important;
          border-top: 1px solid var(--rs-list-border);
          border-bottom: 1px solid var(--rs-list-border);

          .table-column {
            color: $primary-color !important;
            font-size: 8px !important;
            font-weight: bold !important;
            text-transform: uppercase !important;

            &:last-child {
              margin-left: 5px;
            }
          }
        }
      }

      .table-body {
        .table-row:last-child {
          border-bottom: none;
        }
      }
    }

    > .form-panel-body-table-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      font-size: 15px;
      font-weight: bold;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }
}
