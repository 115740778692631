@use "styles/variables.scss" as *;

.table {
  .table-row {
    display: flex;
    flex-direction: row;
    font-weight: normal;
    background-color: white;
    color: #152e41;
    border-left: var(--rs-navs-tab-border) solid 1px;
    border-right: var(--rs-navs-tab-border) solid 1px;
    border-bottom: var(--rs-navs-tab-border) solid 1px;

    &.table-row-empty {
      display: flex;
      flex: 1;
      text-align: center;
      padding: 30px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 5px;

      > .table-row-empty-title {
        font-size: 20px;
        font-weight: bold;
      }

      > .table-row-empty-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        font-size: 17px;
      }
    }

    &.table-row-line-through {
      > .table-column {
        text-decoration: line-through;
      }
    }

    &.table-row-has-overflow {
      padding-right: $scroll-size;
    }

    &.table-row-disabled {
      text-decoration-line: line-through;
    }

    &.table-row-link {
      cursor: pointer;

      &:hover {
        background-color: #f7f7fa;
      }
    }

    &.table-row-highlight {
      background-color: #e7e7ef;
      color: #152e41;

      .table-column {
        font-weight: bold;
      }
    }
  }
}
