#project-section-vibes-view {
  .table-column-number {
    min-width: 55px;
    justify-content: center;

    .section-index {
      display: flex;
      background-color: #385d79;
      color: white;
      justify-content: center;
      align-items: center;
    }

    .section-element-index {
      display: flex;
      background-color: #e7e7ef;
      justify-content: center;
      align-items: center;
    }
  }

  .table-column-name {
    flex: 1;
    min-width: 200px;
  }

  .table-column-product {
    flex: 2;
    min-width: 200px;
  }

  .table-column-client-amount {
    width: 200px;
    justify-content: flex-end;
  }

  .table-column-subcontractor-amount {
    width: 200px;
    justify-content: flex-end;
  }

  .table-column-contractor-amount {
    width: 200px;
    justify-content: flex-end;
  }

  .table-column-promoter-amount {
    width: 200px;
    justify-content: flex-end;
  }

  .table-column-contingency-amount {
    width: 200px;
    justify-content: flex-end;
  }
}
