#products-form {
  .table-column-label {
    flex: 1;
    min-width: 400px;
  }

  .table-column-activities {
    flex: 2;
    min-width: 200px;
    justify-content: flex-start;

    > * {
      width: 100%;
    }
  }

  .table-column-provider {
    flex: 1;
    min-width: 125px;
    justify-content: flex-start;

    > * {
      width: 100%;
    }
  }

  .table-column-provider-product-model {
    flex: 1;
    min-width: 125px;
    justify-content: flex-start;

    > * {
      width: 100%;
    }
  }

  .table-column-provider-product-code {
    flex: 1;
    min-width: 125px;
    justify-content: flex-start;

    > * {
      width: 100%;
    }
  }

  .table-column-provider-product-url {
    flex: 1;
    min-width: 125px;
    justify-content: flex-start;

    > * {
      width: 100%;
    }
  }
}
