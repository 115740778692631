@use "styles/variables.scss" as *;

.tooltip {
  padding: 0;
  margin: 0;
  background-color: transparent;
  color: $primary-color;

  &.tooltip-highlight {
    border-bottom: 2px dashed #a2a7ad;
  }
}
