#project-sections-view {
  .table-column-index {
    width: 67px;
    justify-content: center;

    .section-index {
      display: flex;
      background-color: #385d79;
      color: white;
      justify-content: center;
      align-items: center;
    }

    .section-element-index {
      display: flex;
      background-color: #e7e7ef;
      justify-content: center;
      align-items: center;
    }
  }

  .table-column-name {
    flex: 2;
    min-width: 200px;
  }

  .table-column-type {
    flex: 1;
    min-width: 200px;
  }

  .table-column-number {
    flex: 1;
    min-width: 200px;
  }
}
