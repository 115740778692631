@use "styles/variables.scss" as *;

#report-selection {
  .document {
    > .document-title {
      margin-bottom: 55px;
    }

    > .document-selection-group {
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      margin-bottom: 55px;

      > .document-selection-group-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--rs-list-border);
        padding: 5px 0;

        > .document-selection-group-title {
          font-size: 26px;
          font-weight: bold;
        }

        > .document-selection-group-vibe {
          display: flex;
          flex-direction: column;

          > .document-selection-group-vibe-label {
            display: flex;
            justify-content: flex-end;
            font-size: 10px;
            font-weight: bold;
            text-transform: uppercase;
          }

          > div {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 18px;
            gap: 5px;

            > span {
              font-size: 22px;
            }

            > small {
              font-size: 14px;
            }
          }

          > span {
            display: flex;
            justify-content: flex-end;
            font-size: 16px;
          }
        }
      }

      > .document-selection-group-subtotal {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 0;
        font-weight: bold;
        font-size: 16px;
        border-top: 3px double var(--rs-list-border);
        border-bottom: 3px double var(--rs-list-border);

        > .document-selection-group-subtotal-label {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;

          > small {
            align-self: flex-end;
            font-weight: normal;
          }
        }

        > .document-selection-group-subtotal-amount {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-direction: row;
        }
      }

      > .document-selection-group-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px 0;
        border-bottom: 1px solid var(--rs-list-border);

        &:last-child {
          border-bottom: none;
        }

        > .document-selection-group-item-section-element {
          font-size: 18px;
        }

        > .document-selection-group-item-section-element-option {
          display: flex;
          align-items: flex-start;
          flex-direction: row;
          font-size: 16px;
          gap: 15px;

          > .document-selection-group-item-section-element-option-card {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            gap: 5px;

            > span {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;

              > .section-element-option-type-tag {
                width: 125px;
              }

              &:first-child {
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}
