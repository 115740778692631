@use "styles/variables.scss" as *;

.input-index {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  > .input-index-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 16px;
      width: 16px;
    }
  }

  > .input-index-value {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    height: 20px;
    width: 20px;
    color: $primary-color;
    background-color: #f7f7fa;
    border: var(--rs-navs-tab-border) solid 1px;
    border-radius: 4px;
  }
}
