.section-element-form-panel {
  .form-panel-body-header-has-model-link {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;

    > .form-panel-body-header-has-model-link-label {
      text-transform: uppercase;
    }

    > .form-panel-body-header-has-model-link-value {
      font-weight: bold;
    }
  }

  .form-group-label {
    flex: 1;
    min-width: 250px;
  }

  .form-group-type {
    max-width: 175px;

    .tag {
      font-size: 14px;
      height: 100%;
      justify-content: center;
      flex-direction: column;
      display: flex;
      align-items: center;
    }
  }

  .form-group-group {
    flex: 1;
    min-width: 250px;
  }

  .table-column-index {
    width: 60px;
  }

  .table-column-product {
    flex: 1;
    min-width: 400px;

    > * {
      width: 100%;
    }
  }

  .table-column-type {
    width: 150px;
  }

  .table-column-client-amount {
    width: 126px;
  }

  .table-column-cost-amount {
    width: 138px;
  }

  .table-column-contingency-amount {
    width: 104px;
  }
}
