#section-vibe-form {
  .table-column-index {
    min-width: 30px;
    justify-content: center;
  }

  .table-column-section-element {
    flex: 1;
    min-width: 100px;
  }

  .table-column-section-element-option {
    flex: 3;
    min-width: 200px;
  }

  .table-column-section-element-option-type {
    width: 100px;
  }

  .table-column-subcontractor-amount {
    width: 125px;
  }

  .table-column-contractor-amount {
    width: 125px;
  }

  .table-column-promoter-amount {
    width: 125px;
  }
}
