.contract-selection-group-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  > .contract-selection-group-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--rs-list-border);

    > .section-element-label {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 20px;
      line-height: 20px;
    }

    > .total-amount {
      font-size: 14px;
      line-height: 14px;
    }
  }

  > .contract-selection-group-item-body {
    display: flex;
    flex-direction: column;
    gap: 25px;

    > .section-element-option-set {
      display: flex;
      flex-direction: column;
      gap: 10px;

      > .product-category-name {
        font-size: 16px;
      }
    }

    > .section-element-option-set > .section-element-options,
    > .section-element-options {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 10px;
      column-gap: 15px;
    }
  }
}
