.document-title {
  margin: 55px 0 25px 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  small {
    font-size: 16px;
    margin-bottom: 10px;
  }

  h1 {
    font-weight: bold;
    font-size: 40px;
  }
}
