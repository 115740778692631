@use "styles/variables.scss" as *;

.header-tabs {
  display: flex;
  gap: 7.5px;
  align-items: center;
  background-color: white;
  border-top: solid var(--rs-list-border) 1px;
  height: $header-tabs-height;
  padding: 0 15px;

  > .header-tab {
    font-size: 14px;
    display: flex;
    gap: 7.5px;
    padding: 7.5px;
    user-select: none;

    &:hover {
      cursor: pointer;
    }

    &.header-tab-active {
      background-color: $primary-color;
      font-weight: bold;
      border: 1px solid $primary-color;
      border-radius: 10px;
      color: white;
      cursor: auto;

      &:hover {
        cursor: auto;
      }
    }
  }
}
