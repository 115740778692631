#project-contract-selections-view {
  .table-column-date {
    width: 100px;
  }

  .table-column-label {
    width: 150px;
  }

  .table-column-state {
    width: 125px;
  }

  .table-column-reason {
    flex: 1;
    min-width: 200px;
  }

  .table-column-number-of-extras {
    width: 125px;
  }

  .table-column-total-amount {
    width: 125px;
  }

  .table-column-invoice {
    width: 125px;
  }
}
