@use "styles/variables.scss" as *;

.button-split {
  .rs-btn-primary {
    &:first-child {
      background-color: #385d79;

      &:hover {
        background-color: $primary-color;
      }
    }
  }
}
