.dictionary-list {
  display: flex;
  flex-direction: column;

  > .dictionary-list-item {
    display: flex;
    flex-direction: column;

    > .dictionary-list-item-title {
      font-weight: bold;
    }
  }

  &.dictionary-list-inline {
    gap: 2.5px;

    > .dictionary-list-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
    }
  }
}
