@use "styles/variables.scss" as *;

#tenants-view {
  background-color: #e7e7ef;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  > nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: $navbar-height;
    width: 100%;
    background-color: $primary-color;
    padding: 0 8px 0 16px;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-size: 20px;
      font-weight: bold;
    }

    > .rs-plaintext {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
    }

    > .btn-logout {
      color: #fff;
      margin-left: auto;
    }
  }

  > section {
    display: flex;
    justify-content: center;
    flex-direction: row;

    height: calc(100% - $navbar-height);
    width: 100%;

    > div {
      gap: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      min-width: 500px;
      padding: 20px;

      > h1 {
        font-size: 40px;
        line-height: 40px;
        text-align: center;
        color: $primary-color;
      }

      > p {
        font-size: 20px;
        text-align: center;
        color: $primary-color;
      }

      > ul {
        background-color: #fff;
        list-style-type: none;
        padding: 0;
        margin: 0;
        overflow-y: auto;
        min-height: 500px;
        border: 2px solid $primary-color;
        border-radius: 6px;

        > li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px;
          margin: 0;
          border-bottom: 1px solid $primary-color;
          font-size: 16px;
          font-weight: bold;

          &:hover {
            background-color: $primary-color;
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }
  }
}
