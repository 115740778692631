.view-model {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  > header {
    display: flex;
    flex-direction: column;
    height: 70px;
    background-color: white;
    border-bottom: solid var(--rs-list-border) 1px;

    > .left {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      padding: 0 16px;
      gap: 5px;

      > div {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;

        > .btn-return {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid var(--rs-list-border);
          height: 25px;
          width: 25px;
          padding: 0;
        }

        > div {
          > small {
            font-size: 10px;
            color: rgb(143, 143, 143);
            font-weight: bold;
            margin: 0 !important;
            padding: 0 !important;
            text-transform: uppercase;
            line-height: 10px;
          }

          > h1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 7.5px;
            font-size: 22px;
            margin: 0 !important;
            padding: 0 !important;
            line-height: 22px;

            > small {
              font-size: 20px;
              color: #898989;
            }
          }
        }
      }
    }
  }

  > main {
    display: flex;
    flex-direction: column;
    height: calc(100% - 70px);
    overflow-y: auto;

    .rs-form {
      height: 100%;
    }
  }
}
