.panel-control {
  display: flex;
  flex-direction: column;
  gap: 2.5px;

  > .panel-control-label {
    font-size: 10px;
    text-transform: uppercase;
  }

  > .panel-control-value {
    font-size: 16px;
  }

  > .panel-control-list {
    > .panel-control-list-item {
      font-size: 16px;
    }
  }
}
