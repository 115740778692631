@use "styles/variables.scss" as *;

.enum-label {
  text-align: center;
  margin-bottom: auto;
  margin-top: auto;
  font-size: 10px;
  font-weight: normal;
  padding: 4px;
  border-radius: 4px;

  &.enum-label-appearance-primary {
    background-color: #385d79;
    color: white;
  }

  &.enum-label-appearance-secondary {
    background-color: #e7e7ef;
    color: $primary-color;
  }

  &.enum-label-appearance-warning {
    background-color: #e19c42;
    color: $primary-color;
  }

  &.enum-label-appearance-danger {
    background-color: rgb(225, 59, 59);
    color: white;
  }

  &.enum-label-bold {
    font-weight: bold;
  }

  &.enum-label-plaintext {
    padding: 0;
    border-radius: 0;
    background-color: transparent;

    &.enum-label-appearance-primary {
      color: #385d79;
    }

    &.enum-label-appearance-secondary {
      color: $primary-color;
    }

    &.enum-label-appearance-warning {
      color: #e19c42;
    }

    &.enum-label-appearance-danger {
      color: rgb(225, 59, 59);
    }
  }

  &.enum-label-block {
    width: 100%;
    text-align: center;
  }

  &.enum-label-uppercase {
    text-transform: uppercase;
  }
}
