@use "styles/variables.scss" as *;

#report-unit {
  display: inline-block;

  .document {
    .table {
      margin-bottom: 25px;
    }

    .table-base-title,
    .table-base {
      .table-head {
        .table-row:first-child {
          background-color: #ac9660;

          .table-column {
            color: white;
            background-color: #ac9660;
            min-height: 35px;
            font-size: 12px;
          }
        }
        .table-row:last-child {
          background-color: #d3cab3;

          .table-column {
            color: $primary-color;
            background-color: #d3cab3;
          }
        }
      }
    }

    .table-base-title {
      .table-head {
        .table-row:first-child {
          .table-column-title {
            flex: 1;
            justify-content: center;
            color: white;
            background-color: #ac9660;
          }
        }
      }
    }

    .table-extra-title,
    .table-extra {
      .table-head {
        .table-row:first-child {
          background-color: $primary-color;

          .table-column {
            color: white;
            background-color: $primary-color;
            min-height: 35px;
            font-size: 12px;
          }
        }
        .table-row:last-child {
          background-color: #adb4bd;

          .table-column {
            color: $primary-color;
            background-color: #adb4bd;
          }
        }
      }
    }

    .table-extra-title {
      .table-head {
        .table-row:first-child {
          .table-column-title {
            flex: 1;
            justify-content: center;
            color: white;
            background-color: $primary-color;
          }
        }
      }
    }

    .table-overview {
      .table-head {
        .table-row:first-child {
          background-color: $primary-color;

          .table-column {
            color: white;
            background-color: $primary-color;
            min-height: 35px;
            font-size: 12px;
          }
        }
      }

      .table-column-label {
        flex: 2;
        background-color: #d9d9d9;
        min-width: 100px;
        font-weight: bold;
        text-transform: uppercase;
      }

      .table-column-value {
        flex: 3;
        min-width: 100px;
      }
    }

    .table-base {
      .table-body {
        .table-column-option {
          background-color: #ede8db;
        }
      }
    }

    .table-extra {
      .table-body {
        .table-column-option {
          background-color: #dbdee1;
        }
      }
    }

    .table-base,
    .table-extra {
      .table-column-element {
        flex: 1;
        min-width: 100px;
      }

      .table-column-room {
        flex: 2;
        min-width: 150px;
      }

      .table-column-option {
        flex: 3;
        min-width: 200px;
      }

      .table-column-provider {
        flex: 1;
        min-width: 100px;
      }

      .table-column-provider-product-model {
        flex: 1;
        min-width: 100px;
      }

      .table-column-provider-product-code {
        flex: 1;
        min-width: 100px;
      }

      .table-column-product-format {
        flex: 1;
        min-width: 100px;
      }

      .table-column-comments {
        flex: 1;
        min-width: 100px;
      }
    }
  }
}
