#project-activities-view {
  .table-column-number {
    width: 100px;
  }

  .table-column-title {
    flex: 1;
    min-width: 100px;
  }

  .table-column-description {
    flex: 2;
    min-width: 100px;
  }

  .table-column-number-of-products {
    width: 150px;
  }
}
