#units-form {
  .table-column-number {
    width: 125px;
  }

  .table-column-form {
    flex: 1;
    min-width: 350px;
    justify-content: flex-end;

    > * {
      min-width: 350px;
    }
  }
}
