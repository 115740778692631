@use "styles/variables.scss" as *;

.product-thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  border-radius: 5px;
  background-color: $primary-color;
  user-select: none;

  &.product-thumbnail-bordered {
    border: 2px solid $primary-color;
  }

  &.product-thumbnail-sm {
    width: 60px;
    height: 60px;
    font-size: 8px;
  }

  &.product-thumbnail-md {
    width: 80px;
    height: 80px;
    font-size: 10px;
  }

  &.product-thumbnail-lg {
    width: 175px;
    height: 200px;
    font-size: 14px;
  }
}

div.product-thumbnail {
  padding: 5px;
}
