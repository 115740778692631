@use "styles/variables.scss" as *;

.header-overview {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: $header-overview-height;
  background-color: #f7f7fa;
  padding: 10px 15px;
  gap: 20px;
  border-top: solid var(--rs-list-border) 1px;

  > .card {
    max-width: 450px;
  }
}
