#tenant-settings-users-view {
  .user-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .table-column-details {
    flex: 1;
    min-width: 100px;
  }

  .table-column-tag {
    width: 55px;
  }

  .table-column-role {
    flex: 1;
    min-width: 100px;
  }

  .table-column-projects {
    flex: 1;
    min-width: 100px;

    > ul {
      display: flex;
      flex-direction: column;
    }
  }
}
