@use "styles/variables.scss" as *;

.table {
  .table-row {
    &.table-row-highlight {
      .table-column {
        &.table-column-actions {
          button {
            background-color: $primary-color;
            color: white;
            border: 1px solid $primary-color;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .table-column {
      &.table-column-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;

        button {
          background-color: #e7e7ef;
          color: $primary-color;
          border: 1px solid $primary-color;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
