#project-contracts-view {
  .table-column-date {
    width: 110px;
  }

  .table-column-state {
    width: 200px;
  }

  .table-column-customers {
    flex: 1;
    min-width: 200px;

    .contract-customer-list {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }

  .table-column-selection {
    flex: 1;
    min-width: 200px;
  }

  .table-column-form {
    flex: 1;
    min-width: 200px;
  }

  .table-column-total {
    width: 155px;
    justify-content: flex-end;
  }

  .table-column-paid {
    width: 155px;
    justify-content: flex-end;
  }

  .table-column-refunded {
    width: 155px;
    justify-content: flex-end;
  }

  .table-column-non-refundable {
    width: 155px;
    justify-content: flex-end;
  }

  .table-column-balance {
    width: 155px;
    justify-content: flex-end;
  }

  .table-row-highlight {
    .table-column-date {
      flex: 1;
    }
  }
}
