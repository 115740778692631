.link {
  color: #09243b;
  text-decoration: none;

  &:hover {
    color: #1e90ff;
    text-decoration: underline;
    cursor: pointer;
  }

  &:active {
    color: #064c78;
    text-decoration: underline;
  }
}
