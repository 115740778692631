@use "styles/variables.scss" as *;

.product-card {
  background-color: var(--rs-sidenav-default-bg);
  border: 2px solid var(--rs-list-border);
  width: 175px;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;

  &.product-card-locked {
    cursor: not-allowed;
  }

  > .product-thumbnail {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  > .product-card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100px;
    gap: 5px;

    > .start {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
      padding-left: 10px;
      font-size: 12px;
      gap: 5px;

      > .start {
        display: flex;
        flex-direction: column;

        > .label {
          font-weight: bold;
          flex-wrap: wrap;
        }
      }

      > .end {
        padding-right: 10px;

        > span {
          text-wrap: nowrap;
          font-size: 6px;
          border-radius: 4px;
          background-color: $primary-color;
          color: white;
          text-transform: uppercase;
          font-weight: bold;
          padding: 5px;
        }
      }
    }

    > .end {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      font-size: 12px;
    }
  }
}
