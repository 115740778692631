@use "styles/variables.scss" as *;

.modal.modal-form {
  .rs-modal-header-close {
    display: none;
  }

  .rs-modal-content {
    padding: 0;

    > .modal-header {
      padding: 20px 15px 20px 15px;
      border-bottom: 1px solid var(--rs-list-border);

      > .modal-title {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 20px;
      }
    }

    > .modal-body {
      margin: 0;
      padding: 0;

      label {
        font-weight: bold;
      }

      .form-container {
        > .form-container-header {
          > .form-container-title {
            font-size: 18px;
          }
        }
        &.form-container-fluid {
          padding: 0;

          > .form-container-body {
            > .table {
              > .table-content {
                > .table-body {
                  > .table-row:last-child {
                    border-bottom: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    > .modal-footer {
      border-top: 1px solid var(--rs-list-border);
      padding: 15px;

      button {
        width: 125px;
      }
    }
  }
}
