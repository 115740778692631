@use "styles/variables.scss" as *;

.header-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 7.5px;
  align-items: center;
  height: $header-filter-height;
  padding: 15px;
  background-color: white;
  border-top: solid var(--rs-list-border) 1px;
  width: 100%;

  > .header-filter-left {
    display: flex;
    flex-direction: row;
    gap: 7.5px;

    > .header-filter-left-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
    }

    > .header-filter-left-inputs {
      display: flex;
      gap: 7.5px;

      > * {
        width: 200px;
      }
    }
  }

  > .header-filter-right {
    display: flex;
    justify-content: flex-end;

    > .header-filter-right-inputs {
      display: flex;
      gap: 7.5px;
    }
  }
}
