@use "styles/variables.scss" as *;

#report-invoice {
  padding-top: 75px;
  padding-bottom: 75px;

  .document {
    display: flex;
    flex-direction: column;
    gap: 20px;

    > .invoice-header {
      display: flex;
      justify-content: space-between;

      > .invoice-header-left {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .invoice-project-contact-info {
          display: flex;
          flex-direction: column;

          > address {
            text-transform: uppercase;
          }
        }

        .invoice-project-contact-info-props {
          display: flex;
          flex-direction: column;

          > div {
            display: flex;
            flex-direction: row;
          }
        }
      }

      > .invoice-header-right {
        display: flex;
        flex-direction: column;
        gap: 20px;

        > .invoice-title {
          display: flex;
          flex-direction: column;
          text-align: right;
          gap: 10px;

          > h2 {
            font-size: 40px;
            line-height: 40px;
          }

          > span {
            font-size: 15px;
          }
        }

        > .invoice-balance {
          display: flex;
          flex-direction: column;
          text-align: right;

          > label {
            font-size: 15px;
          }

          > span {
            font-size: 20px;
          }
        }
      }
    }

    > .invoice-body {
      > .invoice-customer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > .invoice-customer-info {
          display: flex;
          flex-direction: column;

          > label {
            margin-bottom: 7.5px;
          }

          > address {
            text-transform: uppercase;
          }
        }

        > .invoice-customer-conditions {
          display: flex;
          flex-direction: column;
          gap: 12.5px;

          > div {
            display: flex;
            justify-content: flex-end;

            > label {
              width: 200px;
              text-align: right;
            }

            > span {
              width: 150px;
              text-align: right;
            }
          }
        }
      }

      > .table {
        margin-top: 20px;

        .table-column-index {
          width: 40px;
        }

        .table-column-description {
          flex: 1;
          min-width: 500px;
          justify-content: flex-start;
        }

        .table-column-quantity {
          width: 50px;
          justify-content: flex-end;
        }

        .table-column-unit-price {
          justify-content: flex-end;
          width: 125px;
        }

        .table-column-amount {
          justify-content: flex-end;
          width: 125px;
        }
      }
    }

    > .invoice-footer {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;

      > .invoice-contract-info {
        background-color: #e7e7ef;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid var(--rs-navs-tab-border);
        margin-left: auto;
        margin-right: auto;
        font-size: 18px;

        > .invoice-contract-info-item {
          display: flex;
          flex-direction: row;

          > label {
            display: flex;
            width: 150px;
          }

          > span {
            font-weight: bold;
          }
        }
      }

      > .invoice-summary {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        row-gap: 5px;
        font-size: 15px;

        > div {
          display: flex;
          justify-content: flex-end;

          > label {
            width: 200px;
            text-align: right;
            padding: 10px;
          }

          > span {
            width: 150px;
            text-align: right;
            padding: 10px;
          }

          &:last-child {
            color: white;
            font-weight: bold;

            > label {
              background-color: var(--rs-navbar-inverse-bg);
            }

            > span {
              background-color: var(--rs-navbar-inverse-bg);
            }
          }
        }
      }
    }
  }
}
