.table {
  .table-row {
    .table-column {
      display: flex;
      text-align: left;
      justify-content: flex-start;
      align-items: center;
      background: transparent;
      padding: 10px 10px;
      flex-wrap: wrap;

      &.table-column-disabled {
        background-color: #868686 !important;
      }

      > ul {
        margin: 0;
        padding: 0;

        > li {
          display: inline;
          margin: 0;
          padding: 0;
        }
      }

      > .rs-input-group,
      > .rs-input,
      > .rs-input-number,
      > .rs-picker {
        width: 100%;

        > .rs-input,
        > .rs-input-number,
        > .rs-picker {
          width: 100%;
        }
      }
    }
  }
}
