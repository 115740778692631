#tenant-settings-user-view {
  .user-name {
    display: flex;
    gap: 7.5px;
  }

  .table-column-name {
    flex: 2;
    min-width: 100px;
    align-items: flex-start;
  }

  .table-column-email {
    align-items: flex-start;
    flex: 2;
    min-width: 100px;
  }

  .table-column-verified {
    align-items: flex-start;
    flex: 1;
    min-width: 50px;
  }

  .table-column-blocked {
    align-items: flex-start;
    flex: 1;
    min-width: 50px;
  }

  .table-column-roles {
    align-items: flex-start;
    flex: 2;
    min-width: 100px;

    > ul {
      display: flex;
      flex-direction: column;
    }
  }

  .table-column-projects {
    align-items: flex-start;
    flex: 2;
    min-width: 100px;

    > ul {
      display: flex;
      flex-direction: column;
    }
  }
}
