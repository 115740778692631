#tenant-settings-taxes-view {
  .table-column-name {
    flex: 1;
    min-width: 100px;
  }

  .table-column-agency {
    flex: 2;
    min-width: 100px;
  }

  .table-column-rate {
    width: 75px;
    justify-content: flex-end;
  }
}
