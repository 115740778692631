#project-contract-invoices-view {
  .table-column-date {
    width: 100px;
  }

  .table-column-label {
    width: 100px;
  }

  .table-column-status {
    width: 125px;
  }

  .table-column-selection {
    flex: 1;
    min-width: 125px;
  }

  .table-column-paid-amount {
    width: 125px;
    justify-content: flex-end;
  }

  .table-column-refunded-amount {
    width: 125px;
    justify-content: flex-end;
  }

  .table-column-total {
    width: 125px;
    justify-content: flex-end;
  }

  .table-column-credit-amount {
    width: 125px;
    justify-content: flex-end;
  }

  .table-column-balance {
    width: 125px;
    justify-content: flex-end;
  }
}
