#contract-selection-conditions-accept-modal {
  text-align: justify;

  .modal-body {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    > p {
      display: flex;
      flex-direction: column;
      text-align: justify;
      gap: 7.5px;
      margin: 0;
      font-size: 16px;

      &:first-child,
      &:last-child {
        text-transform: uppercase;
      }

      > span {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  .modal-footer {
    display: flex;

    button {
      width: 50%;
    }
  }
}
