@use "styles/variables.scss" as *;

.table {
  .table-head {
    display: flex;
    flex-direction: column;

    .table-row {
      background-color: $primary-color;
      border-bottom: none;

      .table-column {
        padding: 7.5px 10px 5px 10px;
        text-transform: uppercase;
        color: white;
        font-weight: 600;
        font-size: 11px;
        min-height: 30px;
      }
    }
  }
}
