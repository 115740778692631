#tenant-settings-permissions-view {
  .table-column-name {
    flex: 1;
    min-width: 100px;
  }

  .table-column-description {
    flex: 2;
    min-width: 100px;
  }
}
