@use "styles/variables.scss" as *;

#project-models-view {
  .table-column-index {
    min-width: 55px;
    justify-content: center;

    .model-index {
      display: flex;
      background-color: #385d79;
      color: white;
      justify-content: center;
      align-items: center;
    }
  }

  .table-column-name {
    flex: 2;
    min-width: 200px;
  }

  .table-column-room-count {
    flex: 1;
    min-width: 100px;
  }

  .table-column-element-count {
    flex: 1;
    min-width: 100px;
  }

  .table-column-option-count {
    flex: 1;
    min-width: 100px;
  }

  .table-column-product-count {
    flex: 1;
    min-width: 100px;
  }
}
