$primary-color: #152e41;
$secondary-color: #385d79;

$font-size-h1: 46px * 0.65;
$font-size-h2: 36px * 0.65;
$font-size-h3: 28px * 0.65;
$font-size-h4: 22px * 0.65;
$font-size-h5: 18px * 0.65;
$font-size-h6: 16px * 0.65;

$line-height-h1: 62px * 0.65;
$line-height-h2: 50px * 0.65;
$line-height-h3: 42px * 0.65;
$line-height-h4: 34px * 0.65;
$line-height-h5: 24px * 0.65;
$line-height-h6: 22px * 0.65;

$enable-ripple-effect: false;

$navbar-height: 56px;

$sidebar-header-height: $navbar-height + 24px;
$sidebar-width: 200px;

$header-filter-height: 40px;
$header-tabs-height: 60px;

$view-footer-form-height: 82px;

$scroll-size: 7.5px;

$header-overview-height: 185px;
