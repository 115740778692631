#project-contract-payments-view {
  .table-column-date {
    width: 100px;
  }

  .table-column-label {
    flex: 1;
    min-width: 110px;
  }

  .table-column-invoice {
    flex: 1;
    min-width: 110px;
  }

  .table-column-selection {
    flex: 2;
    min-width: 125px;
  }

  .table-column-mode {
    flex: 1;
    min-width: 125px;
  }

  .table-column-amount {
    flex: 1;
    min-width: 125px;
  }

  .table-column-reference-number {
    flex: 1;
    min-width: 200px;
  }

  .table-column-notes {
    flex: 2;
    min-width: 200px;
  }
}
