@use "styles/variables.scss" as *;

.dialog {
  &.dialog-centered {
    margin-top: 400px;
  }

  > .rs-modal-dialog > .rs-modal-content {
    border: 1px solid $primary-color;
  }

  .dialog-header {
    > .rs-modal-header-close {
      display: none;
    }

    > .dialog-title {
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}
