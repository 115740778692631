#project-forms-view {
  .table-column-number {
    min-width: 55px;
    justify-content: center;

    .form-index {
      display: flex;
      background-color: #385d79;
      color: white;
      justify-content: center;
      align-items: center;
    }

    .form-section-index {
      display: flex;
      background-color: #e7e7ef;
      justify-content: center;
      align-items: center;
    }
  }

  .table-column-name {
    flex: 1;
    min-width: 200px;

    > .name {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .table-column-state-or-reference {
    flex: 2;
    min-width: 200px;
  }
}
