@use "styles/variables.scss" as *;

.report-message {
  border: 1px solid #2196f3;
  border-top-width: 4px;
  background-color: #c5e7fc;
  color: white !important;

  > .rs-message-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;

    > .rs-message-icon-wrapper {
      align-self: center;

      > .rs-icon {
        font-size: 40px;
      }
    }

    > .rs-message-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 5px;

      > .rs-message-header {
        font-weight: bold;
        font-size: 20px;
      }

      > .rs-message-body {
        margin: 0;
        font-size: 18px;

        > p {
          margin: 0;
          max-width: 700px;
        }
      }
    }
  }
}
