#contract-selection-confirm-form {
  padding: 0 16px;
  margin-bottom: 55px;

  > .rs-message {
    text-transform: uppercase;

    > .rs-message-container {
      padding: 15px;

      .rs-checkbox {
        padding-right: 10px;
      }
    }
  }
}
