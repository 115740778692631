.table {
  .table-body {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: calc(100% - 30px);

    > .table-body-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 750px;

      > h2 {
        font-size: 26px;
        margin-bottom: 5px;
      }

      > p {
        margin-top: 5px;
        text-align: center;
        font-size: 18px;
      }

      > button {
        margin-top: 20px;
      }
    }

    &.table-body-is-empty {
      padding-top: 150px;
      flex-direction: row;
      justify-content: center;
    }
  }
}
