.text {
  &.text-strike {
    text-decoration: line-through !important;
  }

  &.text-highlight {
    color: #006dc0;
  }

  &.text-subtile {
    color: #787e83;
  }
}
