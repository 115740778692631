.not-found {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--rs-navbar-inverse-bg);
  color: white;

  > p {
    font-size: 20px;
    margin-bottom: 30px;
  }

  > small {
    margin-top: 30px;
  }
}
