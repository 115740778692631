#customers-view {
  .table-column-name {
    flex: 1;
    min-width: 100px;
  }

  .table-column-email {
    flex: 1;
    min-width: 100px;
  }

  .table-column-phone {
    flex: 1;
    min-width: 100px;
  }
}
