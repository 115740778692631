.form-errors {
  max-width: 550px;
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 20px;

  &.rs-message-error {
    > .rs-message-container {
      display: flex;
      gap: 20px;

      > .rs-message-icon-wrapper {
        margin: 0;

        > svg {
          width: 25px;
          height: 25px;
        }
      }
      > .rs-message-content {
        > .rs-message-body {
          > ul {
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            margin: 0;
          }
        }
      }
    }
  }
}
