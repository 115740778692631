.table {
  display: flex;
  flex-direction: column;
  height: 100%;

  &.table-border {
    .table-column {
      border-left: 1px solid var(--rs-navs-tab-border);

      &:first-child {
        border: none;
      }
    }
  }

  &.table-fluid {
    .table-row {
      border-left: none;
      border-right: none;
    }
  }

  &.table-md {
    .table-row {
      height: unset;

      .table-column {
        min-height: 46px;
        font-size: 15px;
      }
    }
  }

  &.table-sm {
    .table-row {
      height: unset;

      .table-column {
        min-height: 25px;
        font-size: 10px;
        padding: 5px 10px;
      }
    }
    .table-head {
      .table-row {
        .table-column {
          font-size: 8px;
          padding: 4px 10px 2px 10px;
        }
      }
    }
  }
}
