@use "styles/variables.scss" as *;

.card {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background-color: white;
  height: 100%;
  border-radius: 8px;
  border: 1px solid $primary-color;
  color: $primary-color;

  > .card-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 5px 10px;
    background-color: $primary-color;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
  }

  > .card-body {
    padding: 5px 10px;
    width: 100%;

    > .dictionary-list {
      > .dictionary-list-item {
        font-size: 18px;

        > .dictionary-list-item-title {
          font-weight: bold;
        }
      }
    }
  }
}
