.contract-selection-total {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 55px;

  > .contract-selection-total-title {
    font-size: 26px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0 0 10px 0;
  }

  > .start {
    display: flex;
    flex-direction: column;
    font-weight: normal;
    font-size: 18px;

    > .contract-selection-total-item {
      display: flex;
      font-size: 16px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid var(--rs-list-border);

      > .contract-selection-total-item-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 0;
      }

      > .contract-selection-total-item-amount {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }

  > .end {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
    font-size: 24px;
    border-top: 3px double var(--rs-list-border);
    border-bottom: 3px double var(--rs-list-border);

    > .contract-selection-total-label {
      padding: 10px 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 7.5px;

      > small {
        font-weight: normal;
      }
    }

    > .contract-selection-total-amount {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-direction: row;
    }
  }
}
